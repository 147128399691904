import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import axios from 'axios'
import ApiBaseUrl from '../http/api'

const Home = ({socket}) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post( ApiBaseUrl +'login', {
          email: email,
          password: password
        }).then(response => {
          const rep = response.data;
          console.log('response.data', response.data)
          window.sessionStorage.setItem('token', rep?.access_token);
          window.sessionStorage.setItem('user', JSON.stringify(rep?.user));
          let user = rep?.user;
          user['socker_id'] = socket.id
          // console.log('user', user)
          socket.emit("newUser", user);
          navigate("/chat")
        }).catch(error => {
          console.log('error', error)
        })
        // localStorage.setItem("userName", userName)
        // socket.emit("newUser", {userName, socketID: socket.id})
    }
  return (
    <div className='auth-component'>
      <form className='home__container' onSubmit={handleSubmit}>
          <div className='auth-form-container'>
            <h2 className='home__header'>Sign in to Open Chat</h2>
            <div className="form__group">
              <label htmlFor="email">Email</label>
              <input type="email" 
                name="email" 
                placeholder='Email'
                id='email'
                className='username__input' 
                value={email} 
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="form__group">
              <label htmlFor="password">Mot de passe</label>
              <input type="password" 
                name="password" 
                placeholder='Mot de passe'
                id='password'
                className='username__input' 
                value={password} 
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className='submit-btn-container'>
              <button className='home__cta'>SIGN IN</button>
            </div>
          </div>
      </form>
    </div>
  )
}

export default Home