import React from 'react'
import {useNavigate} from "react-router-dom"

const ChatBody = ({conversation, typingStatus, lastMessageRef, showNewCreatedConv}) => { 
  const navigate = useNavigate()
  const user = JSON.parse(window.sessionStorage.getItem('user'));
  
  
  const handleLeaveChat = () => {
    window.sessionStorage.removeItem('user');
    navigate("/")
    window.location.reload()
  }
  
  return (
    <>
      <header className='chat__mainHeader'>
          <p>Hangout with Colleagues</p>
          <button className='leaveChat__btn' onClick={handleLeaveChat}>LEAVE CHAT</button>
        </header>


        <div className='message__container'>
          {conversation ? 
            conversation?.discussions?.map(message => (
              message.sender_id === user?.id ? (
                <div className="message__chats" key={ 'sender_' + message.id}>
                  <p className='sender__name'>You</p>
                  <div className='message__sender'>
                      <p>{message.message}</p>
                  </div>
                </div>
              ): (
                <div className="message__chats" key={ 'receiver_' + message.id}>
                  <p>{message?.sender?.fullname}</p>
                  <div className='message__recipient'>
                      <p>{message?.message}</p>
                  </div>
                </div>
              )
          )) : (
            ''
          )}

          <div className='message__status'>
            <p>{typingStatus}</p>
          </div>
          <div ref={lastMessageRef} />   
        </div>
    </>
  )
}

export default ChatBody