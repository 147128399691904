import React, { useEffect, useState, useRef} from 'react'
import ChatBar from './ChatBar'
import ChatBody from './ChatBody'
import ChatFooter from './ChatFooter'
import axios from 'axios'
import ApiBaseUrl from '../http/api'

const ChatPage = ({socket}) => { 
  const [conversations, setConversations] = useState([])
  const [typingStatus, setTypingStatus] = useState("")
  const lastMessageRef = useRef(null);
  const user = JSON.parse(window.sessionStorage.getItem('user'));
  const [show, setShow] = useState(false)
  const [showNewCreatedConv, setShowNewCreatedConv] = useState(false)
  const [conv, setConv] = useState();
  const [conversation, setConversation] = useState();
  const [newReceiverId, setNewReceiverId] = useState();

  

  const getApiAndEmit = async (socket) => {
    try {
      const res = await axios.get(
        ApiBaseUrl + "conversations-by-user/" + user?.id
      );
      let data = res.data.data;
      console.log('request data', data)
      socket.emit("all_conversations", data)
    } catch (error) {
      console.error(`Error: ${error.code}`);
    }
  };

  useEffect(() => {
    getApiAndEmit(socket);
  }, [])

  useEffect(()=> {
    // console.log('dd', data)
    console.log('conversations d', conversations)
    socket.on("allConversations", data => [...conversations, setConversations(data)])
  }, [socket, conversations])

  useEffect(()=> {
    // console.log('vv', data)
    console.log('conversations c', conversations)
    socket.on("allConversations", data => [...conversations, setConversations(data)])
  }, [socket, conversations])

  useEffect(()=> {
    socket.on("typingResponse", data => setTypingStatus(data))
  }, [socket])

  useEffect(() => {
    // 👇️ scroll to bottom every time conversations change
    lastMessageRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [conversations, show]);

  const extractObject = (id) => {
    return conversations.filter(row => row.id === id).pop();
  };

  const onShowConv = (conv_id) => {
    setShowNewCreatedConv(false);
    setShow(!show);
    setConv(conv_id)
    setConversation(extractObject(conv_id))
  }

  const onCreateOrShowConversation = (me_id, receiver_id) => {
    const conversation = conversations.filter(row => ((row.initial_sender_id === me_id && row.receiver_id === receiver_id) || (row.initial_sender_id === receiver_id && row.receiver_id === me_id))).pop();
    console.log('conversation', conversation)
    if(conversation){
      onShowConv(conversation?.id)
    }else{
      setShowNewCreatedConv(true)
      setShow(false);
      console.log('receiver_id', receiver_id)
      console.log('me_id', me_id)
      setNewReceiverId(receiver_id)
    }
  } 

  return (
    <div className="chat">
      <ChatBar onCreateOrShowConversation={onCreateOrShowConversation} showNewCreatedConv={showNewCreatedConv} setShowNewCreatedConv={setShowNewCreatedConv} socket={socket} show={show} onShowConv={onShowConv} />
      <div className='chat__main'>
        {show && (
          <ChatBody conversation={conversations.filter(row => row.id === conv).pop()} typingStatus={typingStatus} lastMessageRef={lastMessageRef}/>
        )}
        {showNewCreatedConv && (
          <ChatBody showNewCreatedConv={showNewCreatedConv} onCreateOrShowConversation={onCreateOrShowConversation} typingStatus={typingStatus} lastMessageRef={lastMessageRef}/>
        )}
        <ChatFooter setNewReceiverId={setNewReceiverId} newReceiverId={newReceiverId} onShowConv={onShowConv} setShowNewCreatedConv={setShowNewCreatedConv} showNewCreatedConv={showNewCreatedConv} socket={socket} conv_id={conv ? conv : null} setConversation={setConversation} />
      </div>
    </div>
  )
}

export default ChatPage