import React, {useCallback, useEffect, useState} from 'react'
import axios from 'axios'
import ApiBaseUrl from '../http/api'

const ChatFooter = ({socket, conv_id, setConversation, setShowNewCreatedConv, showNewCreatedConv, onShowConv, newReceiverId, setNewReceiverId}) => {
    const [message, setMessage] = useState("")
    const user = JSON.parse(window.sessionStorage.getItem('user'))
    const [conversations, setConversations] = useState([])

   

    useEffect(() => {
      socket.on("allConversations", data => [...conversations, setConversations(data)])
    }, [])
    // const handleTyping = (e) => {
    //   if(e.key){
    //     socket.emit("typing",`${localStorage.getItem("userName")} is typing`)
    //   }else{
    //     socket.emit("typing",``)
    //   }
    // }

    const extractObject = (id) => {
      console.log('conversations', conversations)
      return conversations.filter(row => row.id === id).pop();
    };

    const handleTyping = useCallback((event) => {
      socket.emit("typing",`${user?.fullname} is typing`)
    }, []);

    useEffect(() => {
      document.addEventListener("keydown", handleTyping, false);
  
      return () => {
        document.removeEventListener("keydown", handleTyping, false);
      };
    }, [handleTyping, socket]);
   
    const handleSendMessage = (e) => {
        e.preventDefault()
        if(message.trim()) {
          let data = {};
          console.log('newReceiverId', newReceiverId)
          if(showNewCreatedConv){
            data = {
              message: message, 
              initial_sender_id: user?.id,
              receiver_id: newReceiverId
            }
          }else{
            data = {
              message: message, 
              sender_id: user?.id,
              conversation_id: extractObject(conv_id)?.id
            }
          }
          
          axios.post(ApiBaseUrl + "discussions", data)
            .then(response => {
              let returned = response.data.data;
              console.log('returned', returned)
              console.log('response.data', response.data)
              socket.emit("all_conversations", returned)
              socket.emit("typing",``)
              if(showNewCreatedConv){
                setShowNewCreatedConv(false);
                setNewReceiverId()
                onShowConv(response.data?.new_conversation_id)
              }else{
                setConversation(conversations.filter(row => row.id === conv_id).pop())
              }
              
            }).catch(error => {
              console.error(`Error: ${error}`);
            })
          
        }
        setMessage("")
    }
  return (
    <div className='chat__footer'>
        <form className='form' onSubmit={handleSendMessage}>
          <input 
            type="text" 
            placeholder='Write message' 
            className='message' 
            value={message} 
            onChange={e => setMessage(e.target.value)}
            // onKeyDown={handleTyping}
          />
          <button className="sendBtn">SEND</button>
        </form>
     </div>
  )
}

export default ChatFooter