import React, {useState, useEffect} from 'react'
import axios from 'axios'
import ApiBaseUrl from '../http/api'

const ChatBar = ({socket, show, onShowConv, setShowNewCreatedConv, showNewCreatedConv, onCreateOrShowConversation}) => {
    const [users, setUsers] = useState([])
    const [chatableUsers, setChatableUsers] = useState([])
    const [conversations, setConversations] = useState([])
    const me = JSON.parse(window.sessionStorage.getItem('user'))
    // const authToken = window.sessionStorage.getItem('token');
    // console.log('authToken', authToken)

    useEffect(()=> {
        socket.on("newUserResponse", data => setUsers(data));
        socket.on("allConversations", data => setConversations(data));
        socket.on("AllChatableUsers", data => setChatableUsers(data));
        console.log('conversations', conversations)
    }, [socket, users, conversations]);

    const getChatableUserAndEmit = async (socket) => {
        try {
          const res = await axios.get(
            ApiBaseUrl + "users"
          );
          let data = res.data.data;
        //   console.log('request data', data)
          socket.emit("all_chatable_users", data)
        } catch (error) {
          console.error(`Error: ${error.code}`);
        }
    };

    useEffect(() => {
        getChatableUserAndEmit(socket);
    }, [])

  return (
    <div className='chat__sidebar'>
        <h2>Open Chat</h2>
        <div>
            <h4  className='chat__header'>CONVERSATIONS</h4>
            <div className='chat__users'>
                {conversations.map(conv => {
                    return (
                        <p 
                            style={{cursor: 'pointer'}} 
                            onClick={() => onShowConv(conv.id)} 
                            key={'conv_' + conv.id}
                        >
                            {/* {`SADIO ${conv.id}`} */}
                            {conv?.initial_sender?.id === me?.id ? (
                                <div className='conv_conv_last_message'>
                                    <span className='conv_other_side_user_fullname'> {conv?.initial_receiver?.fullname} </span>
                                    <span className='conv_other_side_last_message_resume'> {conv?.discussions[conv?.discussions?.length - 1]?.message?.substring(0,10) + '...'} </span>
                                </div>
                            ) : conv?.initial_receiver?.id === me?.id  && (
                                <div className='conv_conv_last_message'>
                                    <span className='conv_other_side_user_fullname'> {conv?.initial_sender?.fullname} </span>
                                    <span className='conv_other_side_last_message_resume'> {conv?.discussions[conv?.discussions?.length - 1]?.message?.substring(0,10) + '...'} </span>
                                </div>
                            )}
                        </p>
                    )
                })}
            </div>
        </div>
        <div>
            <h4  className='chat__header'>CHATABLE USERS</h4>
            <div className='chat__users'>
                {chatableUsers.map(user => { 
                    // console.log('user', user) 
                    return <p onClick={() => onCreateOrShowConversation(me?.id, user?.id)} style={{cursor: 'pointer'}} key={'chatable_user_' + user.id} className={'chatable-user'}>{user.fullname}</p>
                })}
            </div>
        </div>
        <div>
            <h4  className='chat__header'>ACTIVE USERS</h4>
            <div className='chat__users'>
                {users.map(user => <p key={'user_' + user.id}>{user.fullname}</p>)}
            </div>
        </div>
  </div>
  )
}

export default ChatBar